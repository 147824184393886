import {MAINNET_CHAIN_ID, RINKEBY_CHAIN_ID} from "./index";

export const NetAddressMap = {
  [RINKEBY_CHAIN_ID]: {
    MASTER: '0x7dEB2A36CCf642F1ece66CA80CF0e0904034Ef5D',
  },
  [MAINNET_CHAIN_ID]: {
    MASTER: '0x23c54ac322Cba66EDdcf95c5697b53fc3a8a608c',
  },
};

