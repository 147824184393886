export const APP_API = process.env.REACT_APP_API;
export const APP_MEDIA_BASE_URL = process.env.REACT_APP_MEDIA_BASE_URL;
export const APP_TILE_IMAGE = process.env.REACT_APP_MEDIA_BASE_URL + '/tiles';

export const priceEthPerBlock = 1;

export const SOCIAL_URL_TWITTER = 'https://x.com/JudgeOnSol';
export const SOCIAL_URL_DISCORD = 'https://discord.gg/JudgeOnSOL';
export const SOCIAL_URL_WAITLIST = 'https://67aeijbbfrg.typeform.com/to/ol7GQn77';
export const SOCIAL_URL_NOTE = 'https://example.com/notes';

export const SOCIAL_URL_TELEGRAM = '';
export const SOCIAL_URL_INSTAGRAM = '';
export const SOCIAL_URL_OPENSEA = '';
export const SOCIAL_URL_REDDIT = '';

export const OPENSEA_BUYING_URL = 'https://opensea.io/collection/xxx';
