import './index1440.scss';
import './index-desktop.scss';
import './index-desktop-anim.scss';

import ImgBannerLogo from '../../assets/images/judge-desktop/banner/banner-logo.png';
import ImgBannerMan from '../../assets/images/judge-desktop/banner/banner-man.png';

import Img1 from '../../assets/images/judge-desktop/pic1.png';
import Img2 from '../../assets/images/judge-desktop/pic2.png';
import Img3 from '../../assets/images/judge-desktop/pic3.png';
import Img4 from '../../assets/images/judge-desktop/pic4.png';

import ImgSep1 from '../../assets/images/judge-desktop/sep1.png';

import ImgCryptoCourt from '../../assets/images/judge-desktop/crypto-court-bg.png';
import ImgCryptoCourtInnerBg from '../../assets/images/judge-desktop/crypto-court-inner-bg.png';

import ImgCryptoCourt2Horse from '../../assets/images/judge-desktop/horse.gif';

import ImgSolana from '../../assets/images/judge-desktop/solana.png';

import ImgToken1 from '../../assets/images/judge-desktop/token-img1.png';
import ImgToken2 from '../../assets/images/judge-desktop/token-img2.png';
import ImgTokenBottom from '../../assets/images/judge-desktop/token-bottom-bg.png';
import ImgTokenBottom2 from '../../assets/images/judge-desktop/token-bottom-bg2.png';

import ImgTax1Rect from '../../assets/images/judge-desktop/tax/rect.png';
import ImgTax2Rect from '../../assets/images/judge-desktop/tax/rect2.png';
import ImgTax1Star from '../../assets/images/judge-desktop/tax/image 35.png';
import ImgTax1StarSingle from '../../assets/images/judge-desktop/tax/image 36.png';
import ImgTax1Pistol from '../../assets/images/judge-desktop/tax/pistol 4.png';
import ImgHouse from '../../assets/images/judge-desktop/tax/81703340_Wild West Building 2.png';
import ImgGun from '../../assets/images/judge-desktop/tax/128624223_Artillery -12 2.png';
import ImgWanted from '../../assets/images/judge-desktop/tax/tax-wanted-bottom.png';

import ImgTokenomicsBomb from '../../assets/images/judge-desktop/tokenomics/bomb.gif';
import ImgTokenomicsTableWrap from '../../assets/images/judge-desktop/tokenomics/tokenomics-table.png';

import { SOCIAL_URL_DISCORD, SOCIAL_URL_WAITLIST } from '../../config';

import Carousel from '../../components/Carousel/Carousel';
import ImgTeamAngelia from '../../assets/images/judge/teams/angelia.png';
import ImgTeamSimon from '../../assets/images/judge/teams/simon.png';
import ImgTeamDaniel from '../../assets/images/judge/teams/daniel.png';
import ImgTeamGeorge from '../../assets/images/judge/teams/george.png';
import ImgTeamZac from '../../assets/images/judge/teams/zac.png';

import ReactPlayer from 'react-player';
import { Parallax } from 'react-scroll-parallax';
import { useRef, useState } from 'react';
import { sleep } from '../../dnweb3/helpers/utilities';

export const items = [
  {
    img: ImgTeamGeorge,
    title: (
      <>
        George <br />
        Bracher
      </>
    ),
    desc: 'George  is well known in the gamefi and web3 domains, having dedicated himself full-time to these sectors over the past two years. His journey began in the realm of web3 gaming, where he has excelled as a content creator, game design authority, consultant, and advisor. His expertise extends to business development, characterized by robust partnerships and an extensive professional network.',
  },
  {
    img: ImgTeamSimon,
    title: (
      <>
        Simon <br />
        Lewis
      </>
    ),
    desc: "Simon has been involved in the crypto space since 2017. In 2020 he founded and was the CEO of Bundles ($BUND) which grew from $100,000 MC to over $10,000,000 MC. In 2022, he invited the community to build the project alongside him and it has continued to grow and thrive through the bear market. It's now the only licensed crypto sportsbook in the US and still sits at an MC of over $10,000,000. Simon no longer runs the day to day operations but still sits on the board and works as an advisor. He is looking forward to building Judgement in the exciting new sector of MeFi.",
  },
  {
    img: ImgTeamDaniel,
    title: (
      <>
        Daniel <br />
        Soldano
      </>
    ),
    desc: 'Daniel is a Digital and 3D Artist with more than 20 years of experience. His recognizable, authentic style gave Daniel a place among the top 20 crypto artists worldwide and a place of honor among the top NFT projects of the NFT Design Awards. He was also voted among the top 20 crypto artists worldwide at LABITCONF in 2022. Daniel wanted to design Judgement and the town of Crypto Hollow in a style that was different to the anime and futuristic styles that are ubiquitous in the crypto/NFT field and lead a new Western/ranch theme.',
  },
  {
    img: ImgTeamAngelia,
    title: (
      <>
        Angelia <br />
        Shoe
      </>
    ),
    desc: 'Angelia is our Community Manager and in charge of our social channels. She has worked for a number of Solana projects since 2021 and brings her cheery demeanor and project dedication to Judgement.  More to follow. ',
  },
  {
    img: ImgTeamZac,
    title: (
      <>
        Zac <br />
        Bertram
      </>
    ),
    desc: "Zac has been active in the crypto space since 2017, beginning as a trader and investor. For the past few years, he’s focused on project management within web3 gaming, where he’s overseen the development and coordination of a web3 game (Raised 2.7m in funding from various VC's). His role involved working closely with various teams to ensure smooth execution and progress. In addition to his project management work, Zac also serves as a research analyst for a web3 project and portfolio, providing him with valuable insights into the industry from a different lens. His experience spans across gaming, memes, and trading, giving him a well-rounded perspective on the web3 space.",
  },
];

const CRYPTO_COURT_TYPE_WRITER =
  "As the Jury in Crypto Hollow, your $JUDGE tokens empower you to decide who's a hero, who's a villain, and much more. Each vote brings new cases to weigh in on, from sorting out the righteous from the rogues to settling other hot topics. Your vote sets the course for this wild west, so saddle up and make your judgment count! in your six-shooter.";

const HomeDesktop = () => {
  const timerRef = useRef<any>();
  const posRef = useRef<number>(0);

  // Video
  const [mute, setMute] = useState(true);

  // banner
  const [showBanner, setShowBanner] = useState(false);

  // Token section
  const topRef = useRef<HTMLDivElement | any>();
  const typeWriterRef = useRef<HTMLDivElement | any>();
  const tokenSectionRef = useRef<HTMLDivElement | any>();
  const tokenomicsRef = useRef<HTMLDivElement | any>();

  return (
    <>
      <div className="page-body hidden md:block">
        <div className={`banner relative${showBanner ? '' : ' hidden'}`}>
          <div className="banner-content w-full flex items-center flex-col">
            <img className="banner-logo object-cover w-full" src={ImgBannerLogo} />
            <div className="banner-man relative">
              <img className="object-cover w-full" src={ImgBannerMan} />
              <a className="join-waitlist" href={SOCIAL_URL_WAITLIST} target="_blank">
                Join Waitlist
              </a>
            </div>
          </div>
        </div>

        <div className={`html-embed w-embed relative cursor-pointer${showBanner ? ' hidden' : ''}`}>
          <div className="cursor-pointer" onClick={() => setShowBanner(true)}>
            <ReactPlayer
              className="background__video muted"
              url="/video.mp4"
              height={'100%'}
              width={'100vw'}
              style={{ objectFit: 'cover' }}
              playing
              muted={mute}
              loop={false}
              onEnded={() => setTimeout(() => setShowBanner(true), 1500)}
            />
          </div>
          <div className="fixed cursor-pointer btn-mute bottom-8 right-32 text-6 text-white font-bon z-20" onClick={() => setMute((prev) => !prev)}>
            {mute ? 'Unmute' : 'Mute'}
          </div>
        </div>

        <div id="home-top" ref={topRef}>
          <Parallax
            rootMargin={{ top: -20, left: 0, right: 0, bottom: 1000 }}
            onEnter={(ele) => {
              topRef.current?.classList.add('top-anim');
            }}
            onExit={(ele) => {
              topRef.current?.classList.remove('top-anim');
            }}
          />

          <div className="images-wrap flex flex-wrap relative items-start">
            <img className="img1 w-1/4 object-cover" src={Img1} />
            <img className="img2 w-1/4 object-cover mt-7" src={Img2} />
            <img className="img3 w-1/4 object-cover" src={Img3} />
            <img className="img4 w-1/4 object-cover mt-7" src={Img4} />
          </div>

          <div className="container">
            <h1 className="text-center font-bon text-white">Judgement</h1>
            <img className="sep1 object-cover w-3/4 mx-auto my-7.5" src={ImgSep1} />
            <p className="my-2">
              Welcome to Crypto Hollow, the rough and tumble capital of MeFi, where Meme and DeFi collided to forge a new frontier. Here, amidst dust
              and dreams, The Crypto Court decides who’s a hero and who’s a villain, and you’re right in the thick of it as a member of the Jury.
            </p>
            <p className="my-2">&nbsp;</p>
            <p className="my-2">
              Armed with the $JUDGE token, you’ve got the power to vote in this town’s biggest decisions. Every week brings new faces and fresh
              choices, and there’s always a chance for a second vote. Use that vote carefully, because in Crypto Hollow, every decision matters!
            </p>
          </div>
        </div>

        <div id="crypto-court-d" />
        <div id="crypto-court" className="crypto-court bg-wrap relative">
          <img className="object-cover w-full" src={ImgCryptoCourt} />
          <div className="crypto-court-content absolute left-0 w-full">
            <img className="object-cover w-full" src={ImgCryptoCourtInnerBg} />
            <div className="crypto-court-content-inner w-full">
              <h1 className="">The Crypto Court</h1>
              <Parallax
                rootMargin={{ top: 200, left: 0, right: 0, bottom: 800 }}
                onEnter={(ele) => {
                  console.log('[0] The Crypto Court entered.');
                  if (!timerRef.current) {
                    console.log('[0] Timer started.');
                    const timerFunc = async () => {
                      posRef.current = posRef.current + 1;
                      if (posRef.current >= CRYPTO_COURT_TYPE_WRITER.length) {
                        posRef.current = 1;
                        await sleep(5000);
                      }
                      typeWriterRef.current.innerHTML = CRYPTO_COURT_TYPE_WRITER.slice(0, posRef.current + 1);
                      timerRef.current = setTimeout(timerFunc, 50);
                    };
                    timerRef.current = setTimeout(timerFunc, 0);
                  }
                }}
                onExit={(e) => {
                  if (timerRef.current) {
                    clearInterval(timerRef.current);
                    timerRef.current = null;
                  }
                }}
              >
                <p className="typewriter text-left" ref={typeWriterRef}></p>
              </Parallax>
            </div>
          </div>
        </div>

        <div id="crypto-court2" className="relative"></div>

        <Parallax
          id="token-d"
          rootMargin={{ top: -300, bottom: 1100, left: 0, right: 0 }}
          onEnter={(ele) => {
            const tokenEle = tokenSectionRef.current;
            if (tokenEle.classList.contains('token-anim2')) return;
            console.log('[1] onEnter');
            tokenEle.classList?.add('token-anim1');

            setTimeout(() => {
              console.log('[1] anim2 triggered');
              tokenEle.classList?.add('token-anim2');
            }, 5000);
          }}
          /* onExit={(ele) => {
            console.log('[2] onExit');
            tokenSectionRef.current.classList?.remove('token-anim1');
            tokenSectionRef.current.classList?.remove('token-anim2');
          }} */
        />

        <div id="token" className="" ref={tokenSectionRef}>
          <div id="crypto-court2-horse-wrap" className="absolute bottom-5">
            <img id="crypto-court2-horse" src={ImgCryptoCourt2Horse} />
          </div>

          <div className="mr-5">
            <img id="solana-logo" className="object-cover w-full solana-logo" src={ImgSolana} />
          </div>

          <div className="token-content-wrap container2 hidden">
            <img className="sep1 object-cover w-7/12 mx-auto mt-3" src={ImgSep1} />

            <h1 className="token-title">TOKEN UTILITY AND REVENUE STREAMS</h1>

            <h3 className="token-subtitle">$JUDGE Tokens </h3>
            <p className="token-content">
              Your key to participating in the Jury's decision-making process. Use them to cast votes on a range of judgments, from heroes to villains
              and beyond.
            </p>

            <h3 className="token-subtitle">Voting Fee</h3>
            <p className="token-content">Each vote costs a small fee of 0.001 SOL, keeping the wheels of justice turning in Crypto Hollow.</p>

            <h3 className="token-subtitle">Earning Opportunity</h3>
            <p className="token-content">
              Bet on the winning side! If your vote aligns with the majority, you earn a share of the tokens from those who voted otherwise. It’s your
              chance to not just influence but also earn in this wild crypto landscape.
            </p>

            <h3 className="token-subtitle">Weekly Votes</h3>
            <p className="token-content weekly">
              New cases appear every week, giving you constant chances to vote and potentially earn. Sometimes, there’s even a second round of voting
              to spice things up!
            </p>

            <h3 className="token-subtitle">Voting Mechanisms</h3>
            <ul className="voting-list">
              <li>Hero or Villain</li>
              <li>Ansem v. SOL Jakey (KOL vs KOL)</li>
              <li>Hero of the Week</li>
              <li className="leading-9">Meme of the Week</li>
            </ul>

            <p className="note">
              And there you have it, partner! Now that you know the ropes, scroll on down to uncover the finer details of how voting works in Crypto
              Hollow and get ready to cast your judgment!
            </p>
            <div className="token-img1 left-0">
              <img src={ImgToken1} />
            </div>
            <div className="token-img2 right-0">
              <img src={ImgToken2} />
            </div>
          </div>

          <div className="preloading-bg"></div>
        </div>

        <div id="token-bottom">
          <img className="w-full object-cover" src={ImgTokenBottom} />
        </div>

        <div id="token-bottom2">
          <img className="w-full object-cover" src={ImgTokenBottom2} />
        </div>

        <div id="tax-d" />
        <div id="tax">
          <div id="tax1">
            <div className="relative tax1-inner">
              <div className="star-wrap flex">
                <img className="tax1-img object-fill w-full" src={ImgTax1Rect} />
                <div className="flex-auto">
                  <div>
                    <img className="tax1-star-single" src={ImgTax1StarSingle} />
                  </div>
                  <div>
                    <img className="tax1-star2" src={ImgTax1Star} />
                  </div>
                </div>
              </div>
              <div className="tax1-star1 absolute">
                <img className="object-fill" src={ImgTax1Star} />
              </div>
              <div className="tax1-pistol absolute">
                <Parallax rotate={[-90, 20]} rootMargin={{ top: 400, left: 0, right: 0, bottom: 0 }}>
                  <img className="object-fill" src={ImgTax1Pistol} />
                </Parallax>
              </div>

              <div className="tax1-content absolute">
                <Parallax translateX={['-40px', '0px']}>
                  <h1>Voting Pot Rake</h1>
                </Parallax>
                <p className="">
                  <Parallax translateX={['70px', '-10px']}>
                    Listen up, folks, 'cause we got ourselves a rake on every Voting Pot. However many of them{' '}
                    <span className="text-brown2">$JUDGE</span> tokens be movin' from them losers to them winners be facin' a rake tax, just like in
                    those hold em games you get round these parts. Now, here's how the cookie crumbles: we ain’t taxin’ yall for nothin’ that 1% rake
                    tax, gets divided up among them <span className="text-brown2">$JUDGE</span> stakers, rewardin'em for their loyalty and grit!
                  </Parallax>
                </p>
              </div>
            </div>
          </div>

          <div id="tax2" className="relative">
            <div className="flex items-center">
              <Parallax
                className="house-wrap flex-auto"
                onEnter={(ele) => ele.el.classList.add('house-anim')}
                rootMargin={{ top: -20, left: 0, right: 0, bottom: 100 }}
                onExit={(ele) => ele.el.classList.remove('house-anim')}
              >
                <img className="house object-fill w-full" src={ImgHouse} />
              </Parallax>

              <div className="relative">
                <img className="tax2-img" src={ImgTax2Rect} />
                <Parallax
                  className="gun absolute"
                  onEnter={(ele) => ele.el.classList.add('gun-anim')}
                  rootMargin={{ top: -20, left: 0, right: 0, bottom: 100 }}
                  onExit={(ele) => ele.el.classList.remove('gun-anim')}
                >
                  <img src={ImgGun} />
                </Parallax>

                <Parallax
                  className="tax2-content absolute"
                  onEnter={(ele) => ele.el.classList.add('tax2-anim')}
                  rootMargin={{ top: -20, left: 0, right: 0, bottom: 100 }}
                  onExit={(ele) => ele.el.classList.remove('tax2-anim')}
                >
                  <h1 className="">STAKING MECHANISM AND FEES</h1>
                  <p className="">
                    Y'all wanna earn some rewards? Well, then grab yer $JUDGE tokens and stake 'em like a claim in the gold rush! Rewards come rollin'
                    in from two sources: them $JUDGE tokens churned out by that 1 reflective tax and half the SOL votin' fees. It's as simple as
                    hitchin' your wagon to a single pool and watchin' them rewards pour in like rain on parched land. And remember, them rewards keep
                    on comin' steady-like over 7 days, so kick back, relax, and let the tokens roll in.
                  </p>
                </Parallax>
              </div>
            </div>
          </div>
          <img className="wanted-bottom absolute" src={ImgWanted} />
        </div>

        <div id="tokenomics-d" />
        <div id="tokenomics" ref={tokenomicsRef}>
          <Parallax
            rootMargin={{ top: -20, left: 0, right: 0, bottom: 800 }}
            onEnter={(ele) => {
              if (tokenomicsRef.current.classList.contains('tokenomics-anim2')) return;

              tokenomicsRef.current?.classList.add('tokenomics-anim');

              if (parent) {
                const bombEle = tokenomicsRef.current?.getElementsByClassName('bomb')[0] as HTMLImageElement;
                if (bombEle) {
                  bombEle.src = (tokenomicsRef.current?.getElementsByClassName('bomb-cached')[0] as HTMLImageElement).src + `?${Date.now()}`;
                }
              }

              setTimeout(() => {
                tokenomicsRef.current?.classList.remove('tokenomics-anim');
                tokenomicsRef.current?.classList.add('tokenomics-anim2');
              }, 2000);
            }}
            /* onExit={(ele) => {
              tokenomicsRef.current?.classList.remove('tokenomics-anim');
              tokenomicsRef.current?.classList.remove('tokenomics-anim2');
            }} */
          />

          <div className="inner flex relative">
            <div className="tokenomics-left w-5/12">
              <h1 className="">TOKENOMICS ROUND-UP</h1>
              <p className="text-white">
                <span className="text-brown2">$JUDGE</span> offers a blend of community engagement, deflationary tokenomics, rewards distribution, and
                NFT integration. By integrating revenue streams like voting fees and a reflective tax, the project can ensure financial sustainability
                while continuing to offer value to its users and the broader crypto community.
              </p>
            </div>
            <div className="tokenomics-right relative flex-auto">
              <img className="" src={ImgTokenomicsTableWrap} />
              <div className="tokenomics-table">
                <p className="font-bogart text-brown2 pt-10">
                  Now, let's talk turkey about our token, $JUDGE. It's a mix of community spirit, deflationary savvy, and good ol' rewards, with a
                  dash of NFT flair thrown in for good measure.
                </p>
                <div className="flex -mx-8">
                  <div className="w-1/2 px-8">
                    <table className="border-0">
                      <tr>
                        <td className="cell-label">Private Sale</td>
                        <td className="cell-value">10,000,000</td>
                      </tr>
                      <tr>
                        <td className="cell-label">Whitelist</td>
                        <td className="cell-value">7,500,000</td>
                      </tr>
                      <tr>
                        <td className="cell-label">Public</td>
                        <td className="cell-value">15,000,000</td>
                      </tr>

                      <tr>
                        <td className="cell-label">Team</td>
                        <td className="cell-value">12,500,000</td>
                      </tr>
                      <tr>
                        <td className="cell-label">Reserve </td>
                        <td className="cell-value">10,000,000</td>
                      </tr>
                      <tr>
                        <td className="cell-label">Vote Incentives </td>
                        <td className="cell-value">10,000,000</td>
                      </tr>
                    </table>
                  </div>
                  <div className="w-1/2 px-8">
                    <table className="border-0">
                      <tr>
                        <td className="cell-label">LP Incentives</td>
                        <td className="cell-value">3,000,000</td>
                      </tr>
                      <tr>
                        <td className="cell-label">Airdrop </td>
                        <td className="cell-value">5,000,000</td>
                      </tr>
                      <tr>
                        <td className="cell-label">Treasury</td>
                        <td className="cell-value">12,000,000</td>
                      </tr>
                      <tr>
                        <td className="cell-label">Advisors </td>
                        <td className="cell-value">5,000,000</td>
                      </tr>
                      <tr>
                        <td className="cell-label">DEX Liquidity </td>
                        <td className="cell-value">10,000,000</td>
                      </tr>
                      <tr className="total">
                        <td className="cell-label">TOTAL </td>
                        <td className="cell-value">100,000,000</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <img className="bomb" />

          <div className="cache hidden">
            <img className="bomb-cached" src={ImgTokenomicsBomb} />
          </div>
        </div>

        <div id="official-d" />
        <div id="official" className="">
          <div className="official-inner">
            <h1 className="official-title">Court Officials</h1>
            <img className="official-sep object-cover w-full my-10" src={ImgSep1} />

            <div id="official-slider">
              <div className="carousel-multi flex">
                <Carousel slides={items} slidesToScroll={1} loop={false} />
              </div>
            </div>
          </div>
        </div>

        <div id="nft-d" />
        <div id="nft" className="relative w-full">
          <div className="container">
            <div className="nft-content-wrap">
              <h1 className="official-title font-bon text-7.5 leading-10 text-white">Judgement and NFT Rodeo</h1>
              <p className="nft-content ssm:mt-4">
                When the dust settles and the votes are in, them personalities get stamped as either a 'Hero' or a 'Villain'. Then, like a brand on a
                steer, an image showin' that decision gets whipped up and tossed into the auction ring as an NFT. And here's the kicker, folks: them
                NFTs ain't got no fancy creator royalties attached to 'em. Nope, all them proceeds from second-hand sales? They're goin' straight to
                them sellers, like a full bag of gold at the end of a rainbow.
              </p>
            </div>
          </div>
        </div>

        {/* <div id="crypto-holder" className="relative">
          <div className="container">
            <div className="crypto-holder-content-wrap">
              <p className="crypto-holder-content1">
                But before we can go diggin' for gold in them digital hills, we gotta clean up this town. So, who's ready to strap on their spurs and
                saddle up for some good ol' jury duty?
              </p>
              <p className="crypto-holder-content2">
                Saddle up, folks, 'cause we're fixin' to ride into the sunset, where adventure awaits around every bend in the trail! 
              </p>
            </div>
          </div>
        </div> */}

        <div id="join-d" />
        <div id="join" className="relative">
          <a className="join-link" href={SOCIAL_URL_DISCORD} target="_blank">
            Join Community
          </a>
        </div>
      </div>
    </>
  );
};
export default HomeDesktop;
