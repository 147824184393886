import {SET_BLOCK_STATE, SET_BLOCKS, SET_LAST_BLOCK, SET_MINTED_BLOCK_IDS, SET_MODE, SET_TIMESTAMP, SET_TOGGLE_MODE, SET_USER_BLOCK_IDS, SET_VIEWER, TOGGLE_BLOCK, UPDATE_BLOCK_FILE_MAP, UPDATE_FILES} from "./actions";

export enum EnumMode {
  MODE_VIEW = 1,
  MODE_SELECTION = 2,
}


export interface IBlockState {
  mode: EnumMode;
  blocks: Array<number>;
  timestamp: number
  lastBlock?: number | null;
  markedBlock?: number | null;  // marked block ID from "Move to plot"
  viewer: any
  mintedBlockIds: Array<number>;
  userBlockIds: Array<number>;
  bnbPriceUsd?: number;

  files: object;
  blockFileMap: object;
  loaded: boolean,

  nftPrices?:any;
};

export const INIT_BLOCK_STATE: IBlockState = {
  blocks: [],     // selected blocks
  lastBlock: -1,  // last selected block
  markedBlock: -1,  // last hightlighted block.
  timestamp: new Date().getTime(),
  mode: EnumMode.MODE_VIEW,

  viewer: null, // OSD viewer

  // NFT info
  mintedBlockIds: [],
  userBlockIds: [],

  // public
  bnbPriceUsd: 0,

  // app data
  files: {},
  blockFileMap: {},
  loaded: false,
  nftPrices: {},
}

const blockReducer = (state: IBlockState = INIT_BLOCK_STATE, action: any) => {
  switch (action.type) {
    case SET_LAST_BLOCK:
      return {...state, lastBlock: action.payload};
    case SET_BLOCKS:
      return {...state, blocks: action.payload.blocks};
    case SET_MODE:
      return {...state, mode: action.payload};
    case SET_TOGGLE_MODE:
      return {...state, mode: state.mode == EnumMode.MODE_VIEW ? EnumMode.MODE_SELECTION : EnumMode.MODE_VIEW};
    case SET_TIMESTAMP:
      return {...state, timestamp: action.payload};
    case SET_VIEWER:
      return {...state, viewer: action.payload};
    case SET_BLOCK_STATE:
      return {...state, ...action.payload};
    case SET_USER_BLOCK_IDS:
      return {...state, userBlockIds: action.payload};
    case SET_MINTED_BLOCK_IDS:
      return {...state, mintedBlockIds: action.payload};
    case UPDATE_FILES:
      console.log("Files -> Dispatch:", action.payload, action.payload.file_name);
      return {...state, files: {...state.files, [action.payload.file_id]: {...action.payload}}};
    case UPDATE_BLOCK_FILE_MAP:
      console.log("blockFileMap -> Dispatch:", action.payload);
      return {...state, blockFileMap: {...state.blockFileMap, ...action.payload }};
    case TOGGLE_BLOCK:
      return {...state};
    default:
      return {...state};
  }
}

export default blockReducer;
