import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';

import { Provider } from 'react-redux';
import { store } from './redux/store';

import reportWebVitals from './reportWebVitals';
import App from './App';

import './index.css';
import { BrowserRouter } from 'react-router-dom';

import { ParallaxProvider } from 'react-scroll-parallax';

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_SUBGRAPH,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ParallaxProvider>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ApolloProvider>
      </ParallaxProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
