// apollo testing
import {gql} from "@apollo/client";

const LIMIT = 1000;
const LIMIT_HOLDERS = 1000;

const getIdsMinted = gql`
    query getIdsMinted ($skip: Int){
        holderEntities (first: ${LIMIT}, skip: $skip){
            id
            tokenId
        }
    }
  `;

const GetTokenIdsByHolder = gql`
    query GetTokenIdsByHolder ($account: String!, $skip: Int) {
      holderEntities(first: ${LIMIT_HOLDERS}, skip: $skip, where: {newHolder: $account}) {
        id
        tokenId
        timestamp
      }
    }
  `;

const GetHoldersByTokenId = gql`
    query GetHoldersByTokenId ($id: String!) {
      holderEntities(where: {id: $id}) {
        id
        oldHolder
        newHolder
        tokenId
        timestamp
      }
    }
  `;

const GetRecentPurchases = gql`
    query GetRecentBuys {
      transferEntities(first: 12, orderDirection:desc, orderBy: timestamp) {
        id
        from
        to
        tokenId
      }
    }
  `;

const GetRecentPrices = gql`
    query GetRecentPrices {
      priceEntities(first: 1, orderDirection:desc, orderBy: timestamp) {
        id
        oldPrice
        newPrice
        timestamp
      }
    }
  `;

const GetCanineCartels = gql`
    query getCanineCartels ($skip: Int) {
      canineCartels(first: ${LIMIT}, skip: $skip) {
        id
        price
        mintLimit
        
        namingPrice
        namingAllowed
        
        canineSold
        supplyLimit
        saleActive
        reserved
      }
    }
  `;


const GetRegisters = gql`
  query($address: String, $first:Int, $skip: Int, $sortDir:String, $sortBy:String) {
    registers(first: $first, skip: $skip, where: {owner: $address}, orderDirection:$sortDir, orderBy: $sortBy) {
      id
      name
      owner
      style
    }
  }
`;

const AppGql = {
  LIMIT,
  GetCanineCartels,


  LIMIT_HOLDERS,
  getIdsMinted,
  GetTokenIdsByHolder,
  GetHoldersByTokenId,
  GetRecentPurchases,
  GetRecentPrices,
  GetRegisters
};

export default AppGql;
