import './index.scss';
import './index480.scss';
import './index640.scss';
import './index768.scss';

//
import ImgBanner from '../../assets/images/judge/banner-full.png';
import Img1 from '../../assets/images/judge/pic1.png';
import Img2 from '../../assets/images/judge/pic2.png';
import Img3 from '../../assets/images/judge/pic3.png';
import Img4 from '../../assets/images/judge/pic4.png';

import ImgSep1 from '../../assets/images/judge/sep1.png';
import ImgCryptoCourt from '../../assets/images/judge/crypto-court-bg.png';
import ImgCryptoCourtBottom from '../../assets/images/judge/crypto-court-bottom-bg.png';

import ImgSolana from '../../assets/images/judge/solana.png';
import ImgTax1 from '../../assets/images/judge/token1-outline.png';
import ImgTax2 from '../../assets/images/judge/tax2-outline.png';

import ImgTokenomicsTitle from '../../assets/images/judge/tokenomic-title.png';
import ImgTokenomicsStar1 from '../../assets/images/judge/tokenomics-star1.png';
import ImgTokenomicsStar2 from '../../assets/images/judge/tokenomics-star2.png';
import ImgTokenomicsTableWrap from '../../assets/images/judge/tokenomics-table.png';

import ImgNft from '../../assets/images/judge/nft-bg.png';

import ImgJoinWrap from '../../assets/images/judge/join.png';
import { SOCIAL_URL_DISCORD, SOCIAL_URL_WAITLIST } from '../../config';

import Carousel from '../../components/Carousel/Carousel';
import ImgTeamAngelia from '../../assets/images/judge/teams/angelia.png';
import ImgTeamSimon from '../../assets/images/judge/teams/simon.png';
import ImgTeamDaniel from '../../assets/images/judge/teams/daniel.png';
import ImgTeamGeorge from '../../assets/images/judge/teams/george.png';
import ImgTeamZac from '../../assets/images/judge/teams/zac.png';
import HomeDesktop from './index-desktop';
import ReactPlayer from 'react-player';
import { useState } from 'react';

export const items = [
  {
    img: ImgTeamGeorge,
    title: 'George Bracher',
    desc: 'George  is well known in the gamefi and web3 domains, having dedicated himself full-time to these sectors over the past two years. His journey began in the realm of web3 gaming, where he has excelled as a content creator, game design authority, consultant, and advisor. His expertise extends to business development, characterized by robust partnerships and an extensive professional network.',
  },
  {
    img: ImgTeamSimon,
    title: 'Simon Lewis',
    desc: "Simon has been involved in the crypto space since 2017. In 2020 he founded and was the CEO of Bundles ($BUND) which grew from $100,000 MC to over $10,000,000 MC. In 2022, he invited the community to build the project alongside him and it has continued to grow and thrive through the bear market. It's now the only licensed crypto sportsbook in the US and still sits at an MC of over $10,000,000. Simon no longer runs the day to day operations but still sits on the board and works as an advisor. He is looking forward to building Judgement in the exciting new sector of MeFi.",
  },
  {
    img: ImgTeamDaniel,
    title: <>Daniel Soldano</>,
    desc: 'Daniel is a Digital and 3D Artist with more than 20 years of experience. His recognizable, authentic style gave Daniel a place among the top 20 crypto artists worldwide and a place of honor among the top NFT projects of the NFT Design Awards. He was also voted among the top 20 crypto artists worldwide at LABITCONF in 2022. Daniel wanted to design Judgement and the town of Crypto Hollow in a style that was different to the anime and futuristic styles that are ubiquitous in the crypto/NFT field and lead a new Western/ranch theme.',
  },
  {
    img: ImgTeamAngelia,
    title: 'Angelia Shoe',
    desc: 'Angelia is our Community Manager and in charge of our social channels. She has worked for a number of Solana projects since 2021 and brings her cheery demeanor and project dedication to Judgement.  More to follow. ',
  },
  {
    img: ImgTeamZac,
    title: 'Zac Bertram',
    desc: "Zac has been active in the crypto space since 2017, beginning as a trader and investor. For the past few years, he’s focused on project management within web3 gaming, where he’s overseen the development and coordination of a web3 game (Raised 2.7m in funding from various VC's). His role involved working closely with various teams to ensure smooth execution and progress. In addition to his project management work, Zac also serves as a research analyst for a web3 project and portfolio, providing him with valuable insights into the industry from a different lens. His experience spans across gaming, memes, and trading, giving him a well-rounded perspective on the web3 space.",
  },
];

const Home = () => {
  // Video
  const [mute, setMute] = useState(true);

  // banner
  const [showBanner, setShowBanner] = useState(false);

  return (
    <>
      <div className="page-body md:hidden">
        <div className={`banner relative${showBanner ? '' : ' hidden'}`}>
          <img className="object-cover w-full" src={ImgBanner} />
          <a className="join-waitlist" href={SOCIAL_URL_WAITLIST} target="_blank">
            Join Waitlist
          </a>
        </div>

        <div className={`html-embed w-embed relative cursor-pointer${showBanner ? ' hidden' : ''}`}>
          <div className="cursor-pointer" onClick={() => setShowBanner(true)}>
            <ReactPlayer
              className="background__video muted"
              url="/video.mp4"
              height={'100%'}
              width={'100vw'}
              style={{ objectFit: 'cover' }}
              playing
              muted={mute}
              loop={false}
              onEnded={() => setTimeout(() => setShowBanner(true), 1500)}
            />
          </div>
          <div className="absolute cursor-pointer btn-mute bottom-3 right-8 text-4 text-white font-bon z-20" onClick={() => setMute((prev) => !prev)}>
            {mute ? 'Unmute' : 'Mute'}
          </div>
        </div>

        <div className="container px-6 bg-black2">
          <div className="home-top flex flex-wrap relative pt-11 -mx-2.5">
            <div className="w-1/2 pt-7 px-2.5">
              <img className="object-cover w-full" src={Img1} />
              <img className="object-cover w-full mt-7" src={Img2} />
            </div>
            <div className="w-1/2 px-2.5">
              <img className="object-cover w-full" src={Img3} />
              <img className="object-cover w-full mt-7" src={Img4} />
            </div>
          </div>

          <div id="judgement" className="mt-10">
            <h1 className="text-center text-10 sm:text-13.25 font-bon text-white mt-7">Judgement</h1>
            <img className="object-cover w-full" src={ImgSep1} />
            <p className="mt-11 leading-5 sm:leading-7 text-center">
              Welcome to Crypto Hollow, the rough and tumble capital of MeFi, where Meme and DeFi collided to forge a new frontier. Here, amidst dust
              and dreams, The Crypto Court decides who’s a hero and who’s a villain, and you’re right in the thick of it as a member of the Jury.
            </p>
            <p className="mt-8 leading-5  text-center">
              Armed with the $JUDGE token, you’ve got the power to vote in this town’s biggest decisions. Every week brings new faces and fresh
              choices, and there’s always a chance for a second vote. Use that vote carefully, because in Crypto Hollow, every decision matters!
            </p>
          </div>

          <div id="crypto-court" className="crypto-court bg-wrap relative">
            <img className="object-cover w-full" src={ImgCryptoCourt} />
            <div className="crypto-court-content absolute left-0 w-full">
              <div className="crypto-court-content-inner">
                <h1>The Crypto Court</h1>
                <p>
                  As the Jury in Crypto Hollow, your $JUDGE tokens empower you to decide who's a hero, who's a villain, and much more. Each vote
                  brings new cases to weigh in on, from sorting out the righteous from the rogues to settling other hot topics. Your vote sets the
                  course for this wild west, so saddle up and make your judgment count! in your six-shooter.
                </p>
              </div>
            </div>
          </div>

          <div className="bg-wrap relative z-10">
            <div className="crypto-court-bottom">
              <img className="object-cover w-full" src={ImgCryptoCourtBottom} />
            </div>
          </div>
        </div>

        <div id="token">
          <div className="container">
            <img id="solana-logo" className="object-cover w-full" src={ImgSolana} />

            <h1 className="token-title">TOKEN UTILITY AND REVENUE STREAMS</h1>

            <h3 className="token-subtitle">$JUDGE Tokens </h3>
            <p className="token-content">
              Your key to participating in the Jury's decision-making process. Use them to cast votes on a range of judgments, from heroes to villains
              and beyond.
            </p>

            <h3 className="token-subtitle">Voting Fee</h3>
            <p className="token-content">&nbsp;</p>
            <p className="token-content">Each vote costs a small fee of 0.001 SOL,</p>
            <p className="token-content">&nbsp;</p>
            <p className="token-content voting-fee">keeping the wheels of justice turning in Crypto Hollow.</p>

            <h3 className="token-subtitle">Earning Opportunity</h3>
            <p className="token-content">
              Bet on the winning side! If your vote aligns with the majority, you earn a share of the tokens from those who voted otherwise. It’s your
              chance to not just influence but also earn in this wild crypto landscape.
            </p>

            <h3 className="token-subtitle">Weekly Votes</h3>
            <p className="token-content weekly">
              New cases appear every week, giving you constant chances to vote and potentially earn. Sometimes, there’s even a second round of voting
              to spice things up!
            </p>

            <h3 className="token-subtitle">Voting Mechanisms</h3>
            <ul className="voting-list">
              <li>Hero or Villain</li>
              <li>Ansem v. SOL Jakey (KOL vs KOL)</li>
              <li>Hero of the Week</li>
              <li className="leading-9">Meme of the Week</li>
            </ul>
          </div>
        </div>

        <div id="tax">
          <div id="tax1" className="relative">
            <img id="tax1-img" className="object-cover w-full" src={ImgTax1} />
            <div className="tax1-content absolute">
              <div className="mx-auto max-w-72">
                <h1 className="fon-bon text-10 leading-12.5">Voting Pot Rake</h1>
                <p className="font-bogart leading-5 ssm:leading-6">
                  Listen up, folks, 'cause we got ourselves a rake on every Voting Pot. However many of them{' '}
                  <span className="text-brown2">$JUDGE</span> tokens be movin' from them losers to them winners be facin' a rake tax, just like in
                  those hold em games you get round these parts. Now, here's how the cookie crumbles: we ain’t taxin’ yall for nothin’ that 1% rake
                  tax, gets divided up among them <span className="text-brown2">$JUDGE</span> stakers, rewardin'em for their loyalty and grit!
                </p>
              </div>
            </div>
          </div>
          <div id="tax2" className="relative">
            <img id="tax2-img" className="object-cover w-full" src={ImgTax2} />
            <div className="tax2-content absolute">
              <div className="mx-auto max-w-72">
                <h1 className="fon-bon text-7.5 leading-10 text-left">
                  STAKING MECHANISM <br /> AND FEES
                </h1>
                <p className="font-bogart leading-5  ssm:leading-6 text-left pt-4">
                  Y'all wanna earn some rewards? Well, then grab yer $JUDGE tokens and stake 'em like a claim in the gold rush! Rewards come rollin'
                  in from two sources: them $JUDGE tokens churned out by that 1 reflective tax and half the SOL votin' fees. It's as simple as
                  hitchin' your wagon to a single pool and watchin' them rewards pour in like rain on parched land. And remember, them rewards keep on
                  comin' steady-like over 7 days, so kick back, relax, and let the tokens roll in.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="tokenomics">
          <div className="container">
            <div className="tokenomics-title-wrap pt-20">
              <img className="" src={ImgTokenomicsStar1} />
              <h1 className="mx-auto">TOKENOMICS ROUND-UP</h1>
              <img className="ml-64" src={ImgTokenomicsStar2} />
            </div>
            <p className="tokenomicsc-content font-bogart text-white pt-4">
              <span className="text-brown2">$JUDGE</span> offers a blend of community engagement, deflationary tokenomics, rewards distribution, and
              NFT integration. By integrating revenue streams like voting fees and a reflective tax, the project can ensure financial sustainability
              while continuing to offer value to its users and the broader crypto community.
            </p>
            <p className="font-bogart text-brown2 pt-10">
              Now, let's talk turkey about our token, $JUDGE. It's a mix of community spirit, deflationary savvy, and good ol' rewards, with a dash of
              NFT flair thrown in for good measure.
            </p>
            <div className="tokenomics-table-wrap">
              <img className="" src={ImgTokenomicsTableWrap} />
              <div className="tokenomics-table">
                <table className="border-0">
                  <tr>
                    <td className="cell-label">Private Sale</td>
                    <td className="cell-value">10,000,000</td>
                  </tr>
                  <tr>
                    <td className="cell-label">Whitelist</td>
                    <td className="cell-value">7,500,000</td>
                  </tr>
                  <tr>
                    <td className="cell-label">Public</td>
                    <td className="cell-value">15,000,000</td>
                  </tr>

                  <tr>
                    <td className="cell-label">Team</td>
                    <td className="cell-value">12,500,000</td>
                  </tr>
                  <tr>
                    <td className="cell-label">Reserve </td>
                    <td className="cell-value">10,000,000</td>
                  </tr>
                  <tr>
                    <td className="cell-label">Vote Incentives </td>
                    <td className="cell-value">10,000,000</td>
                  </tr>
                  <tr>
                    <td className="cell-label">LP Incentives</td>
                    <td className="cell-value">3,000,000</td>
                  </tr>
                  <tr>
                    <td className="cell-label">Airdrop </td>
                    <td className="cell-value">5,000,000</td>
                  </tr>
                  <tr>
                    <td className="cell-label">Treasury</td>
                    <td className="cell-value">12,000,000</td>
                  </tr>
                  <tr>
                    <td className="cell-label">Advisors </td>
                    <td className="cell-value">5,000,000</td>
                  </tr>
                  <tr>
                    <td className="cell-label">DEX Liquidity </td>
                    <td className="cell-value">10,000,000</td>
                  </tr>
                  <tr className="total">
                    <td className="cell-label">TOTAL </td>
                    <td className="cell-value">100,000,000</td>
                  </tr>
                </table>
              </div>
            </div>
            <div className="pt-20">&nbsp;</div>
          </div>
        </div>

        <div id="official" className="-mt-15">
          <div className="container">
            <h1 className="official-title font-bon text-7.5 leading-10 ssm:mb-5 text-brown">Court Officials</h1>
            <img className="object-cover w-full my-6" src={ImgSep1} />
          </div>

          <div id="official-slider">
            <div className="carousel-multi flex">
              <Carousel slides={items} slidesToScroll={3} loop={false} />
            </div>
          </div>
        </div>

        <div id="nft" className="relative">
          <img className="object-cover w-full" src={ImgNft} />
          <div className="container">
            <div className="nft-content-wrap">
              <h1 className="official-title font-bon text-7.5 leading-10 text-white">Judgement and NFT Rodeo</h1>
              <p className="nft-content ssm:mt-4">
                When the dust settles and the votes are in, them personalities get stamped as either a 'Hero' or a 'Villain'. Then, like a brand on a
                steer, an image showin' that decision gets whipped up and tossed into the auction ring as an NFT. And here's the kicker, folks: them
                NFTs ain't got no fancy creator royalties attached to 'em. Nope, all them proceeds from second-hand sales? They're goin' straight to
                them sellers, like a full bag of gold at the end of a rainbow.
              </p>
            </div>
          </div>
        </div>

        <div id="crypto-holder" className="relative">
          <div className="container">
            <div className="crypto-holder-content-wrap">
              <p className="crypto-holder-content1">
                But before we can go diggin' for gold in them digital hills, we gotta clean up this town. So, who's ready to strap on their spurs and
                saddle up for some good ol' jury duty?
              </p>
              <p className="crypto-holder-content2">
                Saddle up, folks, 'cause we're fixin' to ride into the sunset, where adventure awaits around every bend in the trail! 
              </p>
            </div>
          </div>
        </div>

        <div id="join" className="relative">
          <img className="object-cover w-full" src={ImgJoinWrap} />
          <a className="join-link" href={SOCIAL_URL_DISCORD} target="_blank">
            Join Community
          </a>
        </div>

        {/* <div className="home-top flex flex-wrap flex-row relative w-full">
        <div className="text-white 4xl:max-w-162_5">
          <h1 className="base">
            Non-Fungible <br />
            <span className="anim-1">Threads.</span>
          </h1>
          <p className="mt-4 md:mt-6 lg:max-w-2xl">
            Join the next evolution of the metaculture with exclusive, limited-edition styles fit for the blockchain, and the streets.
          </p>
        </div>
      </div>

      <div className="flex justify-between xl:relative xl:pb-56">
        <div className="drop-box-wrap-margin relative hidden md:block md:w-1/2 lg:max-w-sm"></div>

        <div className="drop-box-wrap relative xl:absolute px-1 md:px-0 max-w-full">
          <div className="drop-box-images absolute">
            <h2 className="rotated">Latest Drop</h2>
            <img className="drop-matrix" src={ImgVector} />
            <img className="cloth" src={ImgCloth} />
            <img className="rare" src={ImgRare} />
          </div>
          <div className="drop-box max-w-full">
            <div className="mt-10"></div>

            <div className="editions text-4.5 hidden">250 editions</div>
            <div className="text-exter-semi-bold text-5 leading-5 mt-2.5">HH Series 001 Hoodie</div>
            <div className="text-exter-semi-bold text-4.5 leading-4.5 mt-4 hidden">1.50 Ξ</div>

            <div className="coming-soon">Coming soon</div>
          </div>
        </div>
      </div>

      <div className="buy-mixed-wrap relative pb-0 lg:pb-10">
        <div className="mt-18">
          <img className="w-full md:max-w-md" src={ImgHypeAndBayc} />
          <p className="mt-4 pt-2">
            Monkey See. Monkey Wear. Ape into a limited-edition hoodie designed exclusively for BAYC members. Dress yourself and your Ape avatar with
            the apparel that will usher in a new era of fashion. The Series 001 drop will have unique hoodies, with some rarer than others.
          </p>
          <img className="mt-11 w-full md:hidden" src={ImgAPE} />
        </div>
      </div> */}
      </div>
      <HomeDesktop />
    </>
  );
};
export default Home;
