import BigNumber from "bignumber.js";
import {bnZero} from "../helpers/utilities";

export interface ICanineCartel {
  id?: string
  price: BigNumber
  mintLimit: number

  canineSold: number
  supplyLimit?: number
  saleActive: boolean
  reserved: number
  namingPrice: BigNumber
  namingAllowed: boolean
}

export const InitCanineCartel: ICanineCartel = {
  canineSold: 0,
  mintLimit: 20,
  price: bnZero(),
  reserved: 0,
  saleActive: true,
  namingPrice: bnZero(),
  namingAllowed: false,
};


