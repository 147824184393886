export const SET_LAST_BLOCK = "SET_LAST_BLOCK";
export const SET_BLOCKS = "SET_BLOCKS";
export const TOGGLE_BLOCK = "TOGGLE_BLOCK";
export const SET_MODE = "SET_MODE";
export const SET_TOGGLE_MODE = "SET_TOGGLE_MODE";
export const SET_TIMESTAMP = "SET_TIMESTAMP";
export const SET_VIEWER = "SET_VIEWER";
export const SET_BLOCK_STATE = "SET_BLOCK_STATE";
export const SET_MINTED_BLOCK_IDS = "SET_MINTED_BLOCK_IDS";
export const SET_USER_BLOCK_IDS = "SET_USER_BLOCK_IDS";
export const UPDATE_FILES = "UPDATE_FILES";
export const UPDATE_BLOCK_FILE_MAP = "UPDATE_BLOCK_FILE_MAP";

export const setLastBlock = (blockId) => ({
  type: SET_LAST_BLOCK,
  payload: blockId
});

export const setBlocks = (blocks) => ({
  type: SET_BLOCKS,
  payload: {blocks}
});

export const toggleBlock = (blockId) => ({
  type: TOGGLE_BLOCK,
  payload: blockId
});


export const setMode = (mode) => ({
  type: SET_MODE,
  payload: mode
});

export const setToggleMode = () => ({
  type: SET_TOGGLE_MODE,
});

export const setTimestamp = (data) => ({
  type: SET_MODE,
  payload: data
});

export const setViewer = (viewer) => ({
  type: SET_VIEWER,
  payload: viewer
});


export const setBlockState = (data) => ({
  type: SET_BLOCK_STATE,
  payload: data
});


export const setMintedBlockIds = (data) => ({
  type: SET_MINTED_BLOCK_IDS,
  payload: data
});
export const setUserBlockIds = (data) => ({
  type: SET_USER_BLOCK_IDS,
  payload: data
});

export const updateFiles = (file) => ({
  type: UPDATE_FILES,
  payload: file
});

export const updateBlockFileMap = (map) => ({
  type: UPDATE_BLOCK_FILE_MAP,
  payload: map
});



