import React, { useState, useEffect, useCallback, useMemo } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import './style.scss';
import PropTypes from 'prop-types';
import { NextButton, PrevButton } from './Buttons';

const propTypes = {
  slides: PropTypes.array.isRequired,
  showPrev: PropTypes.bool,
  showNext: PropTypes.bool,
  loop: PropTypes.bool,
  slidesToScroll: PropTypes.number,
  startIndex: PropTypes.number,
  itemCls: PropTypes.string,
  renderItem: PropTypes.func,
};

const Carousel = (props: any) => {
  const { slides, showPrev, showNext, loop, slidesToScroll, startIndex, itemCls, renderItem } = props;

  const INIT_OPTION = useMemo(
    () => ({
      loop: loop,
      skipSnaps: false,
      containScroll: 'keepSnaps',
      dragFree: true,
      slidesToScroll: slidesToScroll || 0,
      startIndex: startIndex || 0,
    }),
    [slidesToScroll, startIndex, loop],
  );

  const [viewportRef, embla] = useEmblaCarousel(INIT_OPTION as any);

  useEffect(() => {
    if (embla && embla.slideNodes().length !== slides.length) {
      embla.reInit(INIT_OPTION as any);
    }
  }, [embla, slides, INIT_OPTION]);

  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    embla.on('select', onSelect);
    onSelect();
  }, [embla, onSelect]);

  return (
    <div className="embla">
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          {slides.map((x: any, index: number) =>
            renderItem ? (
              renderItem(x, index)
            ) : (
              <div className={`embla__slide relative ${itemCls || ''}`} key={index}>
                <div className="embla__slide__inner">
                  <img className="embla__slide__img" src={x.img} alt={x.title} />
                  <div className="absolute member-desc w-full h-full top-0 left-0 hidden 2xl:flex">
                    <span>{x.desc}</span>
                  </div>
                </div>
                <p className="text-left font-bon text-brown2 text-5 leading-5 mt-4 lg:text-10 lg:leading-10">{x.title}</p>
              </div>
            ),
          )}
        </div>
      </div>
      {showPrev && <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />}
      {showNext && <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />}
    </div>
  );
};

Carousel.propTypes = propTypes;

export default Carousel;
