import WalletConnectProvider from "@walletconnect/web3-provider";
import {BSC_CHAIN_ID, BSC_TEST_NET_CHAIN_ID, MAINNET_CHAIN_ID, RINKEBY_CHAIN_ID} from "../constants";


export interface IAbstractConnectorOptions {
  /*network: string;*/
}

export interface IWalletConnectConnectorOptions
  extends IAbstractConnectorOptions {
  chainId: number;
  rpc?: { [chainId: number]: string };
  bridge?: string;
  qrcode?: boolean;
  qrcodeModalOptions?: { mobileLinks?: string[] };
  networkId?: number,
}

const ConnectToWalletConnect = (
  opts: IWalletConnectConnectorOptions
) => {
  let rpc = {
    // 56: "https://bsc-dataseed1.defibit.io/",
    // https://rinkeby.arbitrum.io/rpc
    [MAINNET_CHAIN_ID]: "https://main-light.eth.linkpool.io/",
    [RINKEBY_CHAIN_ID]: "https://rinkeby-light.eth.linkpool.io/",
    // [BSC_CHAIN_ID]: "https://bsc-dataseed.binance.org/",
    // [BSC_TEST_NET_CHAIN_ID]: "https://data-seed-prebsc-1-s1.binance.org:8545/",
  };

  const provider = new WalletConnectProvider({
    // bridge,
    qrcode: true,
    rpc,
    chainId: opts.chainId
  });

  return provider;
}

/*
const ConnectToWalletConnect = (
  opts: IWalletConnectConnectorOptions
) => {
  return new Promise(async (resolve, reject) => {
    let bridge = "https://bridge.walletconnect.org";
    let qrcode = true;
    let chainId = 56;
    let qrcodeModalOptions = undefined;
    let rpc = {
      // 56: "https://bsc-dataseed1.defibit.io/",
      // 1: "https://bsc-dataseed.binance.org/",
      56: "https://bsc-dataseed.binance.org/",
    };
    if (opts) {
      bridge = opts.bridge || bridge;
      qrcode = typeof opts.qrcode !== "undefined" ? opts.qrcode : qrcode;

      chainId = opts.chainId;
      qrcodeModalOptions = opts.qrcodeModalOptions || undefined;
    }

    const provider = new WalletConnectProvider({
      // bridge,
      qrcode,
      rpc,
      chainId,
      qrcodeModalOptions
    });

    try {
      await provider.enable();
      resolve(provider);
    } catch (e) {
      reject(e);
    }
  });
};*/

export default ConnectToWalletConnect;
