import React from 'react';

export const PrevButton = ({ enabled, onClick }: any) => (
  <button className="embla__button embla__button--prev transform rotate-180" onClick={onClick} disabled={!enabled}>
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="44" height="44" rx="22" fill="white" />
      <path d="M15 22H29" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 15L29 22L22 29" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </button>
);

export const NextButton = ({ enabled, onClick }: any) => (
  <button className="embla__button embla__button--next" onClick={onClick} disabled={!enabled}>
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="44" height="44" rx="22" fill="white" />
      <path d="M15 22H29" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M22 15L29 22L22 29" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </button>
);

export const Thumb = ({ selected, onClick, item }: any) => (
  <div className={`embla__slide embla__slide--thumb ${selected ? 'is-selected' : ''}`}>
    <button onClick={onClick} className="embla__slide__inner embla__slide__inner--thumb" type="button">
      <div className="border-b border-white" title={item.name}></div>
    </button>
  </div>
);

export const DotButton = ({ selected, onClick }: any) => (
  <button className={`embla__dot ${selected ? 'is-selected' : ''}`} type="button" onClick={onClick} />
);
