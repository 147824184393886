import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';

import './index.scss';
import { SOCIAL_URL_DISCORD, SOCIAL_URL_INSTAGRAM, SOCIAL_URL_NOTE, SOCIAL_URL_OPENSEA, SOCIAL_URL_TELEGRAM, SOCIAL_URL_TWITTER } from '../../config';
import { LogoEle } from '../Navbar/Navbar';

const Footer = ({ history }: { history: any }) => {
  return (
    <>
      <div className="footer text-white relative md:hidden">
        <Link to="/" className={`logo-wrap md:mx-auto`}>
          <img src={require('../../assets/images/judge/judgement-logo.png').default} />
        </Link>

        <div className="social-links flex justify-center">
          <a className="social-link discord" href={SOCIAL_URL_DISCORD} target="_blank">
            Discord
          </a>
          <a className="social-link x" href={SOCIAL_URL_TWITTER} target="_blank">
            X
          </a>
          <a className="social-link note" href={SOCIAL_URL_NOTE} target="_blank">
            &nbsp;
          </a>
        </div>
      </div>

      <div className="footer text-white relative hidden md:block">
        <div className="flex h-full items-center">
          <div className="w-4/12">
            <Link to="/#judgement-d" className="nav my-4 hover:opacity-95">
              Judgement
            </Link>
            <Link to="/#crypto-court-d" className="nav my-4 hover:opacity-95">
              The Crypto Court
            </Link>
            <Link to="/#token-d" className="nav my-4 hover:opacity-95">
              TOKEN UTILITY AND REVENUE STREAMS
            </Link>
            <Link to="/#tax-d" className="nav my-4 hover:opacity-95">
              Voting Pot Rake and STAKING MECHANISM AND FEES
            </Link>
          </div>
          <div className="w-4/12 flex flex-col justify-center">
            <Link to="/" className={`logo-wrap md:mx-auto`}>
              <img src={require('../../assets/images/judge/judgement-logo.png').default} />
            </Link>
            <div className="social-links flex justify-center">
              <a className="social-link discord" href={SOCIAL_URL_DISCORD} target="_blank">
                Discord
              </a>
              <a className="social-link x" href={SOCIAL_URL_TWITTER} target="_blank">
                X
              </a>
              <a className="social-link note" href={SOCIAL_URL_NOTE} target="_blank">
                &nbsp;
              </a>
            </div>
          </div>
          <div className="w-4/12">
            <Link to="/#tokenomics-d" className="nav my-4 hover:opacity-95">
              TOKENOMICS ROUND-UP
            </Link>
            <Link to="/#official-d" className="nav my-4 hover:opacity-95">
              Court Officials
            </Link>
            <Link to="/#nft" className="nav my-4 hover:opacity-95">
              JUDGEMENTS AND NFT RODEOS
            </Link>
            <Link to="/#join-d" className="nav my-4 hover:opacity-95">
              Join Our Community
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Footer);
