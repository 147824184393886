import { combineReducers } from 'redux';
import authUser from './auth/reducer';
import blockReducer  from './block/reducer';

const reducers = combineReducers({
  authUser,
  blockData: blockReducer,
});

export default reducers;
