import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import useBodyScrollbar from '../../dnweb3/hooks/useBodyScrollbar';
import { LogoEle } from './Navbar';
import useNavbar from '../hooks/useNavbar';

interface IDropdownProps {
  isOpen: any;
  toggle: any;
}

const NavbarMobile = ({ isOpen, toggle }: IDropdownProps) => {
  const location = useLocation();
  const { renderConnectButton, renderAccountModals } = useNavbar(isOpen);

  useEffect(() => {
    //
  }, [location]);

  return (
    <div
      className={
        isOpen
          ? 'header-nav-modal flex flex-col normal-case text-center items-center text-sm2 text-white bg-black fixed w-full h-full top-0 left-0 z-50 overflow-y-auto pb-10 pt-32 md:pt-10'
          : 'hidden'
      }
    >
      <div className="flex items-center justify-center absolute top-0 left-0 w-full py-7.5 px-8 md:hidden">
        <LogoEle />
      </div>

      <div className="items-center justify-center absolute top-0 left-0 w-full py-7.5 px-8 hidden md:block">
        <div className="ham cursor-pointer absolute left-0 ml-8" onClick={toggle}>
          <svg className="w-12 h-12" width="48" height="48" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
            <path d="M36 6L6 36" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M6 6L36 36" stroke="white" strokeWidth="4" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </div>
      </div>

      <Link to="/#judgement" className="nav my-4 hover:opacity-95 block md:hidden">
        Judgement
      </Link>
      <Link to="/#crypto-court" className="nav my-4 hover:opacity-95 block md:hidden">
        The Crypto Court
      </Link>
      <Link to="/#token" className="nav my-4 hover:opacity-95 block md:hidden">
        TOKEN UTILITY AND REVENUE STREAMS
      </Link>
      <Link to="/#tax" className="nav my-4 hover:opacity-95 block md:hidden">
        Voting Pot Rake and STAKING MECHANISM AND FEES
      </Link>

      <Link to="/#tokenomics" className="nav my-4 hover:opacity-95 block md:hidden">
        TOKENOMICS ROUND-UP
      </Link>
      <Link to="/#official" className="nav my-4 hover:opacity-95 block md:hidden">
        Court Officials
      </Link>
      <Link to="/#nft" className="nav my-4 hover:opacity-95 block md:hidden">
        JUDGEMENTS AND NFT RODEOS
      </Link>
      <Link to="/#join" className="nav my-4 hover:opacity-95 block md:hidden">
        Join Our Community
      </Link>

      {/* --------------- Desktop Menu -------------------- */}
      <Link to="/#judgement-d" className="nav my-4 hover:opacity-95 hidden md:block">
        Judgement
      </Link>
      <Link to="/#crypto-d-court" className="nav my-4 hover:opacity-95 hidden md:block">
        The Crypto Court
      </Link>
      <Link to="/#token-d" className="nav my-4 hover:opacity-95 hidden md:block">
        TOKEN UTILITY AND REVENUE STREAMS
      </Link>
      <Link to="/#tax-d" className="nav my-4 hover:opacity-95 hidden md:block">
        Voting Pot Rake and STAKING MECHANISM AND FEES
      </Link>

      <Link to="/#tokenomics-d" className="nav my-4 hover:opacity-95 hidden md:block">
        TOKENOMICS ROUND-UP
      </Link>
      <Link to="/#official-d" className="nav my-4 hover:opacity-95 hidden md:block">
        Court Officials
      </Link>
      <Link to="/#nft-d" className="nav my-4 hover:opacity-95 hidden md:block">
        JUDGEMENTS AND NFT RODEOS
      </Link>
      <Link to="/#join-d" className="nav my-4 hover:opacity-95 hidden md:block">
        Join Our Community
      </Link>

      <div className="cursor-pointer mt-12 md:hidden" onClick={toggle}>
        <svg className="w-8 h-8" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path d="M18 6L6 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          <path d="M6 6L18 18" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>

      {/*{renderConnectButton()}
      {renderAccountModals()}*/}
    </div>
  );
};

export default NavbarMobile;
