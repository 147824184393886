import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setCanineCartels, setError, setStateData} from "../../redux/auth/actions";
import {useApolloClient, ApolloQueryResult} from "@apollo/client";
import AppGql from "../appollo/AppGql";
import {IMapStateToProps} from "../../types";
import {ICanineCartel} from "../types/canineCartels";
import BigNumber from "bignumber.js";

const useCanineCartelsUpdater = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();
  const appState = useSelector((state: IMapStateToProps) => state);
  const {
    authUser: {
      // address: addressConnected,
    },
  } = appState;

  const updater = useCallback(async () => {
    console.log(" CH-> useCanineCartelsUpdater ");

    let error = false;
    let canineCartels: Array<ICanineCartel> = [];
    try {
      setLoading(true);
      let i = 0;
      while (true && i < 10) {
        const res: ApolloQueryResult<any> = await client.query({
          query: AppGql.GetCanineCartels,
          variables: {
            first: AppGql.LIMIT,
            skip: AppGql.LIMIT * i
          },
          fetchPolicy: "no-cache",
        });
        if (res.error) {
          error = true;
          break;
        }

        if (res.data && res.data.canineCartels) {
          canineCartels = [...canineCartels, ...res.data.canineCartels];
          if (res.data.canineCartels.length < 1 || res.data.canineCartels.length < AppGql.LIMIT) {
            break;
          }
        } else {
          error = true;
          break;
        }
        i++;
      }

      if (error) {
        console.error("Fetching error to get canine cartels");
        dispatch(setError("Fetching error to get canine cartels"));
      } else {
        if (canineCartels.length > 0) {
          for (let idx in canineCartels) {
            canineCartels[idx].namingPrice = new BigNumber(canineCartels[idx].namingPrice);
          }
        }
        dispatch(setCanineCartels(canineCartels));
        if (canineCartels.length > 0) {
          dispatch(setStateData({nftPrices: {normal_price: new BigNumber(canineCartels[0].price || 0)}}));
        }
      }
    } catch (e) {
      console.error(e);
      dispatch(setError(e));
      error = true;
    } finally {
      setLoading(false);
    }

    return (error) ? null : canineCartels;
  }, [dispatch, client]);

  return {loading, updater}
}

export default useCanineCartelsUpdater;
