import React, {useCallback, useContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {doLogin, doLogout, isLoggedIn} from "../dnweb3/helpers/AuthUtil";
import {setLoggedIn, setStateData} from "../redux/auth/actions";
import {NotificationManager} from 'react-notifications';

import {BSC_CHAIN_ID, BSC_TEST_NET_CHAIN_ID, TIMER_INTERVAL} from "../dnweb3/constants";

import useUserNftIdListUpdater from "../dnweb3/hooks/useUserNftIdListUpdater";
import {APP_NETWORK_ID} from "../dnweb3/config";
import useUserBalanceUpdater from "../dnweb3/hooks/useUserBalanceUpdater";
import SLoadingModal from "./Dialog/SLoadingModal";
import {IMapStateToProps} from "../types";

import {gql, useLazyQuery, useQuery} from "@apollo/client";
import AppGql from "../dnweb3/appollo/AppGql";
import {setBlockState, setMintedBlockIds, setUserBlockIds} from "../redux/block/actions";
import {EnumMode, INIT_BLOCK_STATE} from "../redux/block/reducer";
import {bnToDec, bnZero, getBinancePrice, getEthPrice} from "../dnweb3/helpers/utilities";
import AppApi from "../dnweb3/appapi";
import BigNumber from "bignumber.js";
import useMintedNftIdListUpdater from "../dnweb3/hooks/useMintedNftIdListUpdater";
import useCanineCartelsUpdater from "../dnweb3/hooks/useCanineCartelsUpdater";


/**
 * A loading component with the main biz logic to fetch data
 * Polling by timer. Default interval is 60s
 *
 * @returns {*}
 * @constructor
 */
export default function LoadingData() {
  const dispatch = useDispatch();

  const {
    authUser: {
      loading,
      loadingType,
      connectType,
      networkId: curNetworkId,
      address,
      loggedIn,
      web3
    }
  } = useSelector((state: IMapStateToProps) => state);

  // Fetching public data
  const {loading: loadingCanineCartels, updater: updateCanineCartels} = useCanineCartelsUpdater();

  /**
   * Real time login status
   */
  useEffect(() => {
    // todo: Please remove this
    const func = async () => {
      if (web3 && address) {
        const dataToSign = `I am signing my one-time nonce: ${123}`;
        web3.eth.personal.sign(dataToSign, address, '', [(a: any, b: any) => {
          console.log(a, b);
        }]).then((res: string) => {
          console.log("sign result: ", res);
          // web3.eth.personal.ecRecover(dataToSign, res).then(console.log);
        });
      }
      return null;
    }
    // const response = func();
  }, [web3, address]);


  useEffect(() => {
    // Already logged in?
    if (isLoggedIn(curNetworkId, address, connectType)) {
      dispatch(setLoggedIn(true));
    }

    /*fetchPublicData();
    const interval = setInterval(async () => {
      fetchPublicData();
    }, TIMER_INTERVAL * 1000);*/

    // return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    if (curNetworkId && address && connectType) {
      if (loggedIn) doLogin(curNetworkId, address, connectType);
    }
    if (!loggedIn) {
      dispatch(setBlockState({
        userBlockIds: [],
        mode: EnumMode.MODE_VIEW
      }));
    }
  }, [loggedIn]);

  useEffect(() => {
    if (curNetworkId && curNetworkId != APP_NETWORK_ID) {
      // @ts-ignore
      if (APP_NETWORK_ID == BSC_CHAIN_ID)
        NotificationManager.info("Please select Binance Smart Chain network to proceed!");
      else if (APP_NETWORK_ID == BSC_TEST_NET_CHAIN_ID)
        NotificationManager.info("Please select Binance Smart Chain Test network to proceed!");
    }
  }, [curNetworkId]);

  useEffect(() => {
    if (loggedIn && address && curNetworkId) {

    }
  }, [loggedIn, address, curNetworkId]);


  useEffect(() => {
    const loginOk = isLoggedIn(curNetworkId, address, connectType);
    if (loginOk && !loggedIn) {
      dispatch(setLoggedIn(true));
    }
  }, [loggedIn, address, curNetworkId, connectType]);

  /**
   * Update NFT lists
   */
  const fetchData = useCallback(async () => {
    if (address && loggedIn) {

    }
  }, [dispatch, address, loading, loggedIn]);


  /**
   * Update NFT lists
   */
  const fetchPublicData = useCallback(async () => {
    updateCanineCartels();
  }, [dispatch]);

  return (
    <><SLoadingModal isOpen={loading && loadingType > 0}/></>
  );
};
