import React, { useEffect, useState } from 'react';
import { Link, useRouteMatch, useLocation } from 'react-router-dom';
import { SConnectModal } from '../Dialog/SConnectModal';
import { useDispatch, useSelector } from 'react-redux';
import { IMapStateToProps } from '../../types';
import { ellipseAddress } from '../../dnweb3/helpers/utilities';
import SAccountModal from '../Dialog/SAccountModal';
import LinkButton from '../LinkButton';
import Button from '../Button';

import ImgMetamask from '../../assets/images/icon/metamask-i.png';
import './Navbar.scss';
import useNavbar from '../hooks/useNavbar';
import { SOCIAL_URL_DISCORD, SOCIAL_URL_TWITTER } from '../../config';

interface INavbarProps {
  toggle: any;
  isOpen: boolean;
  width?: number;
  height?: number;
}

export const LogoEle = ({ extraCls, width, height }: any) => (
  <Link to="/" className={`logo-wrap md:mx-auto ${extraCls || ''}`}>
    <img src={require('../../assets/images/judge/logo-menu.png').default} width={width ?? 337} height={height ?? 112} />
  </Link>
);

const Navbar = ({ toggle, isOpen }: INavbarProps) => {
  const location = useLocation();
  const { renderConnectButton, renderAccountModals } = useNavbar(isOpen);

  useEffect(() => {}, [location]);

  return (
    <React.Fragment>
      <div className="header-nav-wrap absolute z-10 w-full">
        <nav
          className="header-nav flex justify-center items-center uppercase text-white relative text-sm2 pt-7.5 md:pt-10 px-8 md:px-8 xl:px-16"
          role="navigation"
        >
          <div className="cursor-pointer fixed left-0 ml-8 md:hidden" onClick={toggle}>
            <svg className="w-8 h-8" fill="#ffffff" stroke="#ffffff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path fill="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </div>
          <div className="ham cursor-pointer fixed left-0 ml-8 hidden md:block" onClick={toggle}>
            <svg className="w-12 h-12" fill="#ffffff" stroke="#ffffff" viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
              <path fill="#ffffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4" d="M8 12h32M8 24h32M8 36h32" />
            </svg>
          </div>
          <LogoEle />
        </nav>
      </div>
    </React.Fragment>
  );
};

export default Navbar;
