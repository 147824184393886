import React, { useCallback, useEffect, useRef } from 'react';
import { IMapStateToProps } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import SLoadingWrap from '../SLoadingWrap';
import { CT_METAMAST, CT_WALLET_CONNECT, StriteWallets } from '../../dnweb3/constants';
import useBodyScrollbar from '../../dnweb3/hooks/useBodyScrollbar';

const getLogoSrc = (id: string, status: string) => {
  switch (id) {
    case CT_METAMAST:
      return require('../../assets/images/icon/metamask.png').default;
      break;
    case CT_WALLET_CONNECT:
      return require('../../assets/images/icon/wallet-connect.png').default;
      break;
  }
};

interface ISConnectModalProps {
  isOpen: any;
  onClose: any;
}

const SConnectModal = ({ isOpen, onClose }: ISConnectModalProps) => {
  const modalRef: any = useRef();
  const dispatch = useDispatch();

  const loading = useSelector((state: IMapStateToProps) => state.authUser.loading);
  const onConnect = useSelector((state: IMapStateToProps) => state.authUser.onConnect);
  const address = useSelector((state: IMapStateToProps) => state.authUser.address);
  const loggedIn = useSelector((state: IMapStateToProps) => state.authUser.loggedIn);

  useBodyScrollbar(isOpen);

  useEffect(() => {
    const body: any = document.querySelector('body');
    if (isOpen) {
      body.classList.add('modal-active');
      modalRef.current.classList.remove('pointer-events-none');
    } else {
      body.classList.remove('modal-active');
      modalRef.current.classList.add('pointer-events-none');
    }
  }, [isOpen /*, loading, loadingType*/]);

  const handleConnect = useCallback(
    async (connectType: string) => {
      try {
        await onConnect(connectType, false, onClose);
      } catch (e) {
        console.error(e);
      }
    },
    [isOpen, dispatch, onConnect],
  );

  return (
    <>
      {
        <div
          className={`modal ${
            isOpen ? '' : 'opacity-0'
          } normal-case pointer-events-none fixed w-full h-full top-0 left-0 flex items-center shadow-lg justify-center z-50`}
          ref={modalRef}
        >
          <div className="modal-overlay absolute w-full h-full bg-black opacity-50" onClick={onClose}></div>
          <div className="modal-container bg-white w-11/12 md:max-w-md mx-auto shadow-lg z-50 overflow-y-auto text-black">
            <SLoadingWrap className="modal-content py-12 text-left px-8 items-center justify-center" loading={loading}>
              <h3 className="text-3xl font-bold font-bon text-center">Connect your wallet</h3>
              <div className="dialog connect-dialog flex flex-col bg-white text-center font-exter-regular">
                <p className="text-lg font-normal mt-4">Connect with one of our available wallet providers below.</p>
                <div className="flex flex-cols-2 flex-wrap">
                  {StriteWallets.map((row) => (
                    <div
                      key={row.id}
                      className="flex flex-1 mx-2 cursor-pointer justify-between items-center flex-col py-4 px-4 mt-6 border border-black border-opacity-75 hover:bg-gray-100"
                      onClick={(e) => handleConnect(row.id)}
                    >
                      <img src={getLogoSrc(row.id, '')} width={40} alt={row.name} />
                      <span className="text-xl mt-4 font-bold">{row.name}</span>
                      <div style={{ width: 20 }}></div>
                    </div>
                  ))}
                </div>
                <p className="font-sm text-sm mt-8 text-black opacity-50">
                  We do not own your private keys and cannot access your funds without your confirmation.
                </p>
              </div>
            </SLoadingWrap>
          </div>
        </div>
      }
    </>
  );
};

// @ts-ignore
export { SConnectModal };
