import {all} from 'redux-saga/effects';
import authSagas from './auth/saga';
import blockSaga from './block/saga';


export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    blockSaga()
  ]);
}
